.infoContainer {
  border-bottom: 1px solid #dcdcdc;
  width: 50%;
}

.infoTitle {
  background-color: #e1e5ff;
  width: 50%;
  font-size: large;
  color: #474747;
  display: flex;
  align-items: center;

}

.infoBody {
  font-size: 1rem;
  width: 60%;
}
.infoBody .detail{
  color: #36429E;
  cursor: pointer;

}

.center-text {
  /* text-align: center; */
  vertical-align: middle;
}


