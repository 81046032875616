.guageContainer {
  border-radius: 24px;
  padding: 50px 50px 0px ;
  height: 100%;
  position: relative;
  padding-top: 17px;

}
.disappled {
  background-color: #f7f7f7;
}
.active {
  background-color: #eceef9;
}
.btn-page {
    position: absolute;
    bottom: 22px;
    right: 10px;
}
