.container {
    background-color: #ECEEF9;
    padding: 20px 30px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    width: 32%;
}
.imgContainer{
    background-color: #F0443833;
    padding: 10px;
    border-radius: 50%;
}
.textContainer{
    margin-left: 20px;
}