table {
    width: 80%;
    margin-top: 20px;
    margin-left: 7px;
   
}

th,
td {
    padding: 17px;
    font-size: 16px;
    line-height: 24px;
    border-bottom: 1px solid rgba(174, 169, 169, 0.169);
    color: #474747d1;

}



