.ChartConatiner {
  padding: 20px 10px 0; 
  width: 49%;
  max-height: 500px;
  display: flex;
  text-align: center;
  justify-content: center;
  border: 2px solid #c5caed;
  border-radius: 10px;
  
  
}
h3{
    font-weight: 700;
    font-size: x-large;
    margin-bottom: 20px;
    
}
