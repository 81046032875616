@keyframes slideDown {
    from {
        max-height: 200;
        opacity: 0;
    }
    to {
        max-height: 500px; /* Adjust this value to fit the content height */
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        max-height: 500px; /* Same as above */
        opacity: 1;
    }
    to {
        max-height: 200;
        opacity: 0;
    }
}

.tableContainer {
    overflow: hidden;
}

.slide-down {
    animation: slideDown 1s ease-out forwards;
}

.slide-up {
    animation: slideUp 1s ease-out forwards;
}
