.deviceScoreContainer {
  background-color: #fff;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid #eceef9;
}
.deviceprogressIcon {
  width: 332px;
  position: relative;
}
.deviceIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.deviceprogress {
  color: #4453c5;
  font-size: 60px;
  font-weight: bolder;
}
.deviceTxt {
  font-weight: bld;
  font-size: 24px;
  color: #9c9c9c;
}
.low {
  color: #f04438;
  font-weight: bold;
}

.average {
  color: #ffb200;
  font-weight: bold;
}
.high {
  color: #4453c5;
  font-weight: bold;
}
