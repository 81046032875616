.tabsHeader {
    width: 25%;
}
.haederItem {
  background-color:#ECEEF9;
  padding: 10px 35px;
  border-radius: 20px;
  color: #3D4BB1;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}
.active {
  background-color: #3D4BB1;
  color: #FFFFFF;
}
