.add-btn {
    background: #0ACF97;
    color: white;
    padding: 12px 16px;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    transition: all .5;
}

.add-btn:hover {
    background-color: #09b785;
}