.chartContainer {
  background-color: #eceef9;
  padding: 15px;
  border-radius: 10px;
}
.barChartContainer {
  border: 2px solid #c5caed;
  border-radius: 10px;
  margin-top: 20px;
  padding: 40px 60px;
}
.maxUsageAPPContainer {
  margin-top: 20px;
}
.maxUsageAPPContainer h3 {
  background-color: #eceef9;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 24px;
  font-weight: 600;
}
