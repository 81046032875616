.table {
    width: 100%;
}

.table thead th {
    background-color: white;
    padding: 26px 10px;
}

.table tbody {
    background-color: white;
}

