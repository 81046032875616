.organizationHeader {
    display: flex;
    justify-content: space-between;
}

.unassignedDevices {
    width: 165px;
    height: 49px;
    background-color: #F7F7F7;
    border: 1px solid #f7f7f716;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px;
    border-radius: 6px;
}

.icon {
    padding: 6px;
    background-color: #ECEEF9;
    border-radius: 50%;
    width: 20%;
}

.organizationHeader svg {
    margin: 0 0 0 2px;
    padding: 0;
}

.data {
    width: 70%;
}

.data p {
    font-size: 13px;
    font-weight: 800;
    color: #36429E;
}

.data span {
    font-size: 12px;
    font-weight: 500;
}

.addNew {
    font-size: 12px;
    letter-spacing: 1px;
    background-color: #4453C5;
    color: white;
    border-radius: 6px;
    transition: all 1s;

}

.addNew:hover {
    background-color: #36429E;
}

/* TR STYLING .... */
.mainRow {
    position: relative;
}

.mainRow td div {
    margin: 5px 0px;
    padding: 18px;
    background-color: var(--light-blue);
    height: 60px;
}

.mainRow td {
    color: #6A6A6A;
    padding: 0;
    border: 0;
}

tr.mainRow td:first-child div {
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    color: #36429E;
}

tr.mainRow td:last-child div {
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
}