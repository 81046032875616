/* Slide-down effect */
@keyframes slideDown {
    from {
      height: 0;
      opacity: 0;
    }
    to {
      height: auto;
      opacity: 1;
    }
  }
  
  .slide-down {
    animation: slideDown 0.3s ease-out forwards;
  }
  
  /* Slide-up effect */
  @keyframes slideUp {
    from {
      height: auto;
      opacity: 1;
    }
    to {
      height: 0;
      opacity: 0;
    }
  }
  
  .slide-up {
    animation: slideUp 0.3s ease-in forwards;
  }
  