#sidebar {
    width: 275px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 999;
    color: #fff;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    background: linear-gradient(180.15deg, #4453C5 -38.5%, #4355C6 -38.5%, #4356C6 -14.65%, #4258C7 -11.62%, #405DC9 -1.45%, #3A6FCE 64.28%, #19D3EB 131.31%);
    color: #ffffff;
    overflow-y: scroll;
}   

/* Hide scrollbar for Chrome, Safari and Opera */
#sidebar::-webkit-scrollbar {
    width: 0;
    height: 0;
}

/* Hide scrollbar for IE, Edge, and Firefox */
#sidebar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

#sidebar.active {
    margin-left: -275px;
}

.sidebar-header {
    text-align: center;
}

.sidebar-header .top-triangle {
    display: block;
    width: 0;
    height: 0;
    border-left: 0 solid transparent;
    border-right: 18px solid transparent;
    border-top: 18px solid #ffffff;
    position: absolute;
    left: 10px;
    top: 10px;
}

.sidebar-header .logo-container {
    background: #4453C5;
    padding: 20px 0;
}

.sidebar-header .logo {
    width: 150px;
    margin-left: 5%;
}

#sidebarCollapse {
    font-size: 1.5rem;
    color: #4650be;
    padding: 0;
    height: 70px;
    line-height: 70px;
    width: 60px;
    margin-left: -1.5rem;
}

#sidebarCollapse:hover,
#sidebarCollapse:focus {
    color: #38409e;
}

#main-menu {
    padding: 10px;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
}

#main-menu li a {
    color: rgba(255, 255, 255, 0.8);
}

#main-menu li a:hover,
#main-menu li a:focus {
    color: #ffffff;
}

#main-menu>li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}

#main-menu>li:last-child {
    border-bottom: 0;
}

#main-menu>li>a {
    padding: 0.938rem 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 150%;
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#main-menu>li>a .menu-item-icon {
    margin-right: 10px;
}

#main-menu>li>a .menu-item-title {
    margin-top: 1%;
}

/* #main-menu>li>a i {
    margin-right: 0.625rem;
} */
#main-menu>li>a [class^="icon-"]::before {
    margin: 0;
}

#main-menu>.active>a {
    padding: 11px 16px;
    font-size: 15px;
    background: #19D3EB;
    color: #fff;
    border-radius: 6px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

/* #main-menu>.active>a:hover {
    color: rgba(255, 255, 255, 0.8);
} */

#main-menu>.active>a:focus,
#main-menu>.active>a:active {
    color: #ffffff;
}

#main-menu .submenu-collapse li {
    margin-left: 2rem;
}

#main-menu .submenu-collapse li a {
    padding: 0.5rem 0.938rem;
    font-size: 0.938rem;
}

#main-menu .submenu-collapse li:first-child {
    padding-top: 0.625rem;
}

#main-menu .submenu-collapse li:last-child {
    padding-bottom: 0.625rem;
}

#main-menu .submenu-collapse .active a {
    color: #ffffff;
}

.dropdown-toggle::after {
    font-family: "fontello";
    content: "\e940";
    border: none;
    float: right;
    margin-left: 0.4rem;
    /* margin-top: 35%; */
}

.dropdown-toggle[aria-expanded="false"]::after {
    content: "\e93e";
}

@media (max-width: 1000px) {
    #sidebar {
        margin-left: -275px;
        background: #4650be;
    }

    #sidebar.active {
        margin-left: 0;
    }
}

/* Sidebar Start */
.sidebar-links-list li {
    margin: 0.5rem;
    /* padding: 0.5rem 0 0.5rem 0rem !important; */
    width: 100%;
}

.sidebar-links-list li .bullet {
    margin-right: 10px;
}

.sidebar-link-active {
    background: rgba(221, 248, 252, 0.2);
    border-radius: 6px;
}

.sidebar-link {
    margin: 0;
    padding: 0 !important;
    margin-left: 0 !important;
    padding-left: 2.5rem;
}

.backBtnContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    background-color: #364290
}

/* Sidebar End */