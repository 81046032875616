.guagesContainer {
  display: flex; 
  justify-content: space-between; 
  align-items: stretch; 
  gap: 20px; 
  padding-top: 20px; 
}
.guagesContainer > div {
  width: 33%;
  border-radius: 8px; 
}
