.listingHeader-container {
    background-color: #F7F7F7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: var(--border-radius);
}

.inputcontaier {
    border: 1px solid rgba(179, 174, 174, 0.365);
    border-radius: var(--border-radius);
    overflow: hidden;
    padding: 0 10px;
    background-color: #fff;
    display: flex;
    align-items: center;
}

input {
    border: none;
    padding: 10px 5px;
    width: 300px;

}

button {
    padding: 10px;
    background-color: var(--light-blue);
    border-radius: var(--border-radius);
}

button:hover {
    background-color: var(--dark-blue);
}

button:last-child {
    margin-left: 10px;
}